import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import lazyPlugin from 'vue3-lazy'
import ElementPlus from 'element-plus'
import axios from 'axios';
import i18n from './lang/index.js'
import animated from 'animate.css'
import 'element-plus/dist/index.css'
import '@/assets/css/style.css'
import '@/assets/css/common.css'
import '@/assets/css/elementUi.css'
import '@/assets/css/swiper-bundle.css'
import WOW from "wow.js";
import '@splidejs/vue-splide/css';
//  图片懒加载指令
import imgPlugin from '@/utils/directive'

new WOW({
  boxClass: 'wow', // 类名，在用户滚动时显示隐藏的框。
  animateClass: 'animated', // 触发CSS动画的类名称（animate.css库默认为“ animated”）
  offset: 0, // 定义浏览器视口底部与隐藏框顶部之间的距离。当用户滚动并到达此距离时，隐藏的框会显示出来。
  mobile: true, // 在移动设备上打开/关闭WOW.js。
  live: true // 在页面上同时检查新的WOW元素。
}).init();


createApp(App).use(router).use(imgPlugin).use(ElementPlus).use(animated).use(i18n).use(lazyPlugin, {
  loading: require('@/assets/images/loading.gif'),
  error: require('@/assets/images/loading.gif')
}).mount('#app')
